<script setup lang="ts">
import type {PropType} from "vue";
const FrmForm = defineAsyncComponent(() => import("~/components/Redmonkey/FrmForm/FrmForm.vue"))

const props = defineProps({
  formId: String as PropType<undefined | string | null>
})

const isStepForm = ref(false);

const getSteps = (stepsString: string) => {
  const stepsArray: Array<object> = [];

  const steps = stepsString.split('---step start---').slice(1);

  steps.forEach((step, index) => {
    const stepObj: {[key: string]: any} = {};

    const lines = step.split('\n').map(line => line.trim()).filter(Boolean);
    lines.forEach(line => {
      if (line.startsWith('---step end---')) return;

      const [key, value] = line.split(/:\s(.+)/);
      if (key && value) {
        if (value.startsWith('[') && value.endsWith(']')) {
          stepObj[key] = JSON.parse(value);
        } else {
          stepObj[key] = value.replace(/(^"|"$)/g, '');
        }
      }
    });

    stepObj['type'] = 'step';
    stepObj['id'] = `form-${props.formId}-step-id-${index}`;
    stepObj['field_key'] = stepObj['id'];
    stepObj['name'] = stepObj['id'];
    stepObj['default_value'] = '';
    stepObj['required'] = '0';

    stepsArray.push({
      ...stepObj,
      field_options: {
        placeholder: ''
      }
    });
  });

  return stepsArray;
}
const buildStepsFields = (fields: Array<any>, info: object) => {
  isStepForm.value = true;

  const steps = getSteps(fields[0].description);
  fields[0].description = '';
  fields[0].type = 'hidden';

  const fieldStep = {
    type: 'step',
    id: `form-${props.formId}-step`,
    field_key: `form-${props.formId}-step`,
    name: 'origin',
    default_value: '',
    required: '0',
    field_options: {
      placeholder: ''
    },
    fields: fields,
    info
  }

  return [
    ...steps,
    fieldStep
  ];
}

const {data: formData} = await useFetch(`/api/wordpress/getFrmForm/${props.formId}`, {
  method: "POST",
})

const {getFrmForm} = formData.value;

let fields = JSON.parse(getFrmForm?.fields || "[]");

const info = JSON.parse(getFrmForm?.form || "{}");

if(fields?.[0]?.description?.indexOf('---step start---') > -1) {
  fields = buildStepsFields(fields, info);
}

const form = ref({
  info: info || {},
  fields: fields || []
})
</script>

<template>
  <div class="frm-form-wrapper" v-if="form && form.info && form.fields">
    <FrmForm :form-id="formId" :form="form" :is-step-form="isStepForm"></FrmForm>
  </div>
</template>